import React, { useCallback, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import useOutsideDirectory from './UseOutsideDirectory';
import FilterButtonIcon from './FilterButtonIcon';
import ScrollTopButton from './ScrollTopButton';
import StandardDialog from '../Modals/StandardDialog';
import { CompanyProfile } from '../Vendors/Profile';
import { getPaginatedCompanies } from '../../services/company';
import { Company, Regions } from '../../interfaces/users.interface';
import GetBadge from './GetBadge';
import { DataCell, DataListContain, DataRow, DataRowActions } from '../Tables/DataList';
import CompanyForm from '../Companies/Forms/CompanyForm';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, TextField, CircularProgress, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { debounce } from 'lodash';
import { fetchAllServiceCategories } from '../../app/store/serviceCategories';
import { CompanyResponse } from '../../interfaces/company.interface';
import { selectBadgeFromArray } from '../../services/badge';

export function titleCase(str: string) {
  return str.toLowerCase().split(' ').map(function (word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

const CompanyDataRow = (props: { company: Company, index: number }) => {
  const theme = useTheme();
  const company = props.company;
  const { user } = useAppSelector(state => state.user);
  const [profileOpen, setProfileOpen] = useState(false);
  const handleProfileOpen = () => setProfileOpen(true);
  const handleProfileClose = () => setProfileOpen(false);
  const navigate = useNavigate();

  const navToCompany = (companyId: number | undefined) => {
    if (!companyId || (user?.type !== 'tpn_admin' && user?.type !== 'content_owner')) return;
    if (companyId) navigate(`${companyId}`);
  };

  return (
    <DataRow onClick={() => navToCompany(company.id)} hover={true}>
      <StandardDialog handleClose={handleProfileClose} isOpen={profileOpen}>
        <CompanyProfile company={company} />
      </StandardDialog>
      <DataCell xs={1}>{company.logo ? <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={company.logo} alt="logo" /> : <></>}</DataCell>
      <DataCell xs={8}>
        <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontWeight: '500', color: theme.palette.customThemeColors?.darkGrey }} 
        title={`${company.name}\
        ${(!!company.akaNames && company.akaNames.length > 0) ? ('\nAKA: ' + company.akaNames.join(', ')) : ''}\
        ${(!!company.fkaNames && company.fkaNames.length > 0) ? ('\nFKA: ' + company.fkaNames.join(', ')) : ''}\
        ${(!!company.dbaNames && company.dbaNames.length > 0) ? ('\nFKA: ' + company.dbaNames.join(', ')) : ''}`}>
          {company.name}
        </Box>
      </DataCell>
      <DataCell xs={1} sx={{ fontWeight: '400', color: theme.palette.customThemeColors?.darkGrey }}>{company.type ? company.type === 'vendor' ? 'Service Provider' : titleCase(company.type.replace('_', ' ')) : null}</DataCell>
      <DataCell xs={1}>{company.type == 'vendor' ? <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}><GetBadge shield={selectBadgeFromArray(company.activeWorkflowBadges)}/></Box> : null}</DataCell>
      <DataCell xs={1}>
        {user?.type === 'tpn_admin' && <DataRowActions>
          <IconButton size="small" name="Edit User" onClick={(e)=> {e.preventDefault(); handleProfileOpen(); }}><EditIcon /></IconButton>
        </DataRowActions>}
      </DataCell>
    </DataRow>
  );
};

const CompanyListContainer = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(state => state.user);
  const { serviceCategories } = useAppSelector(state => state.serviceCategories);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shield, setShield] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const [sortValue, setSortValue] = useState<string>('relevance');
  const [count, setCount] = useState<number>(0);
  const [selectedServiceCategories, setSelectedServiceCategories] = useState<number[]>([]);
  const [type, setType] = useState<string>((user?.type !== 'tpn_admin') ? 'vendor' : '');
  const [operatingRegions, setOperatingRegions] = useState<string[]>([]);
  const [filteredCompanyList, setFilteredCompanyList] = useState<Company[]>([]);
  const [newCompanyOpen, setNewCompanyOpen] = useState(false);
  const observerRef = useRef<HTMLDivElement | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [topLoading, setTopLoading] = useState<boolean>(false);
  const handleNewCompanySetOpen = () => setNewCompanyOpen(true);
  const handleNewCompanyClose = () => setNewCompanyOpen(false);
  const handleSubmit = (company: Company) => {
    setFilteredCompanyList([ company, ...filteredCompanyList]);
    handleNewCompanyClose();
  };
  const limit = 20;
  const handleServiceCategoriesChange = (e: SelectChangeEvent<number[]>) => {
    let newValue = typeof e.target.value === 'string' ? [+e.target.value] : e.target.value;
    if (newValue.at(-1) === 0) {
      newValue = [];
    }
    setSelectedServiceCategories(newValue);
  };

  const handleRegionsChange = (e: SelectChangeEvent<string[]>) => {
    let newValue = typeof e.target.value === 'string' ? [e.target.value] : e.target.value;
    if (!e.target.value.at(-1)) {
      newValue = [];
    }
    setOperatingRegions(newValue);
  };

  const fetchCompanies = useCallback(async (shouldConcat: boolean, offsetValue: number, isTopLoading: boolean) => {
    if (isTopLoading) {
      setTopLoading(true);
    } else {
      setIsLoading(true);
    }
    setHasMore(true);
    getPaginatedCompanies(`?limit=${limit}&offset=${offsetValue}${type.length ? `&type=${type}` : ''}${user?.type !== 'tpn_admin' ? '&is_published=true' : ''}${search.length ? `&search=${search}` : ''}${shield.length ? `&display_shield_type=${shield}` : ''}${selectedServiceCategories.length ? `&service_categories=${selectedServiceCategories}` : ''}${operatingRegions.length ? `&operating_regions=${operatingRegions.join(',')}` : ''}${sortValue !== 'relevance' ? `&ordering=${sortValue}` : search.length ? '' : '&ordering=name'}`)
      .then((res: CompanyResponse) => {
        setCount(res.count);
        setFilteredCompanyList(res.results
          .filter(comp => user?.type === 'tpn_admin' || !comp?.isHidden)
          .sort((a, b) => a.name.localeCompare(b.name)),
        );
        const newCompanies = res.results.filter(
          comp => user?.type === 'tpn_admin' || !comp?.isHidden,
        );
        const newFilteredCompanyList = shouldConcat ? [ ...filteredCompanyList, ...newCompanies] : newCompanies;
        setFilteredCompanyList(newFilteredCompanyList);
        if (res.results.length < limit) { // If less than limit we are are the end
          setHasMore(false);
        }
      })
      .finally(() => setTimeout(() =>isTopLoading ? setTopLoading(false) : setIsLoading(false), 250));
  }, [selectedServiceCategories, offset, type, user?.type, search, shield, operatingRegions, sortValue]);
 
  const debounceSetSearch = useRef(
    debounce((value: string) => setSearch(value), 750),
  ).current;

  // Effect to cancel debounced calls on unmount
  useEffect(() => () => debounceSetSearch.cancel(), []);
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    debounceSetSearch(value); // Debounced search term update
  };

  useEffect(() => {
    if (!serviceCategories || !serviceCategories.length) dispatch(fetchAllServiceCategories());
  }, []);

  // fetch companies list after changing the search queries or sort value
  useEffect(() => {
    setOffset(0);
    fetchCompanies(false, 0, true);
  }, [shield, search, type, selectedServiceCategories, operatingRegions, sortValue]);

  useEffect(() => {
    if (offset !== 0) {
      fetchCompanies(true, offset, false);
    }
  }, [offset]);

  // Handle intersection observer logic
  const handleObserver = useCallback((entries: any) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore && !isLoading && !topLoading) {
      setOffset(prevOffset => prevOffset + limit);
    }
  }, [isLoading, hasMore, topLoading]);
  
  useEffect(() => {
    // Set up the IntersectionObserver to observe the target element
    const observer = new IntersectionObserver(handleObserver, { threshold: 1.0 });
    if (observerRef.current) observer.observe(observerRef.current);
  
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [handleObserver]);

  const clearSearch = useCallback(() => {
    setInputValue('');
    debounceSetSearch.cancel();
    setSearch('');
  }, []);

  useEffect(() => {
    setOffset(0);
  }, [inputValue]);

  const clearCategories = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelectedServiceCategories([]);
  }, []);

  const clearTypeFilter = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setType('');
  }, []);

  const clearRegion = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOperatingRegions([]);
  }, []);

  const clearShield = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShield('');
  }, []);

  // Service category filter ctrl
  const serviceCategoryRef = useRef(null);
  const [openSCSelect, setOpenSCSelect] = useState(false);
  const closeServiceCategories = useCallback(() => setOpenSCSelect(false), []);
  const handleOpenSCSelect = useCallback(() => setOpenSCSelect(true), []);
  useOutsideDirectory(serviceCategoryRef, openSCSelect, closeServiceCategories);

  // Region filter ctrl
  const regionRef = useRef(null);
  const [openRegionSelect, setOpenRegionSelect] = useState(false);
  const closeRegion = useCallback(() => setOpenRegionSelect(false), []);
  const handleOpenRegion = useCallback(() => setOpenRegionSelect(true), []);
  useOutsideDirectory(regionRef, openRegionSelect, closeRegion);

  // Shield filter ctrl
  const shieldRef = useRef(null);
  const [openShieldSelect, setOpenShieldSelect] = useState(false);
  const closeShield = useCallback(() => setOpenShieldSelect(false), []);
  const handleOpenShield = useCallback(() => setOpenShieldSelect(true), []);
  useOutsideDirectory(shieldRef, openShieldSelect, closeShield);

  // Company filter ctrl 
  const companyRef = useRef(null);
  const [openCompanyTypeSelect, setOpenCompanyTypeSelect] = useState(false);
  const closeCompany = useCallback(() => setOpenCompanyTypeSelect(false), []);
  const handleOpenCompanyType = useCallback(() => setOpenCompanyTypeSelect(true), []);
  useOutsideDirectory(companyRef, openCompanyTypeSelect, closeCompany);

  const handleSortValueChange = useCallback((e: SelectChangeEvent<string>) => {
    setSortValue(e.target.value);
  }, []);

  const maxWidth1 = user?.type === 'tpn_admin' ? '1690px' : '1266px';
  const minWidth1 = user?.type === 'tpn_admin' ? '1691px' : '1267px';
  const maxWidth2 = user?.type === 'tpn_admin' ? '1066px' : '816px';
   
  const mediaQueries = {
    [`@media (max-width: ${maxWidth1})`]: {
      top: '-7em',
    },
    [`@media (min-width: ${minWidth1})`]: {
      top: '-3.5em',
    },
    [`@media (max-width: ${maxWidth2})`]: {
      top: '-10.5em',
    },
  };

  return (
    <Box sx={{
      width: '100%',
    }}>
      <DataListContain title="Companies" buttonTitle={user?.type === 'tpn_admin' ? 'Company' : undefined} buttonClick={handleNewCompanySetOpen}>
        <Box sx={{
          position: 'absolute',
          top: user?.type === 'tpn_admin' ? '-7em' : '-3.5em',
          right: user?.type === 'tpn_admin' ? '10em' : 0,
          maxWidth: user?.type === 'tpn_admin' ? '70%' : '80%',
          flexWrap: 'wrap',
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
          ...mediaQueries,
        }}>
          <FormControl>
            <InputLabel size='small' id="service-category-select-label">Service Categories</InputLabel>
            <Select
              labelId="service-category-select-label"
              id="service-category-select"
              multiple
              ref={serviceCategoryRef}
              value={selectedServiceCategories!}
              onChange={handleServiceCategoriesChange}
              onClick={() => setOpenSCSelect(true)}
              label='Service Categories'
              size="small"
              open={openSCSelect}
              sx={{ width: '185px', backgroundColor: '#fff' }}
              IconComponent={() => <FilterButtonIcon openOptions={handleOpenSCSelect} clearMethod={clearCategories} showClear={selectedServiceCategories.length > 0} /> }
            >
              {serviceCategories && serviceCategories.length > 0 && serviceCategories.map(sc => (
                <MenuItem key={sc.id} value={sc.id}>{sc.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {user?.type === 'tpn_admin' && <FormControl>
            <InputLabel size='small' id="type-select-label">Company Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={type}
              ref={companyRef}
              onChange={(e) => setType(e.target.value)}
              label='Company Type'
              size="small"
              sx={{ width: '185px', backgroundColor: '#fff' }}
              onClick={() => setOpenCompanyTypeSelect(true)}
              open={openCompanyTypeSelect}
              IconComponent={() => <FilterButtonIcon openOptions={handleOpenCompanyType} clearMethod={clearTypeFilter} showClear={type.length > 0} /> }
            >
              <MenuItem value="assessor">Assessor</MenuItem>
              <MenuItem value="content_owner">Content Owner</MenuItem>
              <MenuItem value="vendor">Service Provider</MenuItem>
            </Select>
          </FormControl>}
          <FormControl>
            <InputLabel size='small' id="operating-regions-select-label">Regions</InputLabel>
            <Select
              required
              multiple
              fullWidth
              id='operating-regions'
              labelId='operating-regions-select-label'
              label='Regions'
              ref={regionRef}
              value={operatingRegions}
              open={openRegionSelect}
              size="small"
              sx={{ width: '130px', backgroundColor: '#fff' }}
              onChange={handleRegionsChange}
              onClick={() => setOpenRegionSelect(true)}
              IconComponent={() => <FilterButtonIcon openOptions={handleOpenRegion} clearMethod={clearRegion} showClear={operatingRegions.length > 0} /> }
            >
              {Regions.map(region => (
                <MenuItem key={region} value={region}>{region}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel size='small' id="shield-select-label">Shield Level</InputLabel>
            <Select
              labelId="shield-select-label"
              id="shield-select"
              value={shield}
              onChange={(e) => setShield(e.target.value)}
              label='Shield Level'
              size="small"
              ref={shieldRef}
              sx={{ width: '130px', backgroundColor: '#fff' }}
              open={openShieldSelect}
              onClick={() => setOpenShieldSelect(true)}
              IconComponent={() => <FilterButtonIcon openOptions={handleOpenShield} clearMethod={clearShield} showClear={shield.length > 0} /> }
            >
              <MenuItem value="tpn_assessed">Gold Shield</MenuItem>
              <MenuItem value="tpn_self_reported">Blue Shield</MenuItem>
              <MenuItem value="non_tpn_self_reported">Non TPN Self Reported</MenuItem>
              <MenuItem value="tpn_member">No Shield</MenuItem>
            </Select>
          </FormControl>
          <TextField size='small' label="Search"
            InputProps={{
              endAdornment: (
              <InputAdornment position="end">
              {search && (
                <IconButton
                  aria-label="clear search"
                  onClick={clearSearch}
                  edge="end"
                  size="small"
                  sx={{ '& svg': { fontSize: '1rem' } }} 
                >
                  <ClearIcon />
                </IconButton>
              )}
              </InputAdornment>
              ),
              sx: { background: 'white', minWidth: '275px' },
            }}
            value={inputValue}
      onChange={handleSearchChange}  /> 
        </Box>
        <StandardDialog handleClose={handleNewCompanyClose} isOpen={newCompanyOpen} title="New Company">
          <CompanyForm displayTypeField={true} successCallback={handleSubmit} submitButtonText='Submit' assignUserToEmployees={false} />
        </StandardDialog>
        <DataRow onClick={() => {}} header>
          <DataCell xs={1}>{''}</DataCell>
          <DataCell xs={8}>Company</DataCell>
          <DataCell xs={1}>Type</DataCell>
          <DataCell xs={1}>Shield</DataCell>
          <DataCell xs={1}>&nbsp;</DataCell>
        </DataRow>
        {topLoading && [...Array(5)].map((e, i) =>
            <DataRow header key={i}>
              <DataCell xs={1}><Skeleton width='100%' height='35px' /></DataCell>
              <DataCell xs={8}><Skeleton width='100%' height='35px' /></DataCell>
              <DataCell xs={1}><Skeleton width='100%' height='35px' /></DataCell>
              <DataCell xs={1}><Skeleton width='100%' height='35px' /></DataCell>
              <DataCell xs={1}><Skeleton width='100%' height='35px' /></DataCell>
            </DataRow>,
        )}
        <ScrollTopButton />
      {!topLoading && (
        <DataRow>
      <DataCell xs={6}>
        <Box
          display="flex"
          alignItems="center"
          sx={{ fontWeight: '400', color: theme.palette.customThemeColors?.lightGray, textAlign: 'left' }}
        >
          Showing {count} results
        </Box>
      </DataCell>
      <DataCell xs={6}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography sx={{ marginRight: 1, fontWeight: '400', color: theme.palette.customThemeColors?.lightGray }}>Order By</Typography>
          <FormControl>
            <Select
              size='small'
              value={sortValue}
              onChange={handleSortValueChange}
              sx={{ width: '150px', backgroundColor: '#fff', color: '#626262' }}
            >
              <MenuItem value="relevance">Relevance</MenuItem>
              <MenuItem value="name">
                Ascending (A-Z)
              </MenuItem>
              <MenuItem value="-name">
                Descending (Z-A)
              </MenuItem>
              <MenuItem value="-created_at">
                Newest
              </MenuItem>
              <MenuItem value="created_at">
                Oldest     
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DataCell>
    </DataRow>
      )}
        { !topLoading && filteredCompanyList.map((company: Company, index: number) => <CompanyDataRow key={company.id} company={company} index={index} />)}
        <div ref={observerRef} style={{ height: '100px' }}>
          { !!isLoading && (<Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
            }}
          >
            <CircularProgress
              thickness={1.8}
              sx={{
                color: theme.palette.customThemeColors?.lightGray,
              }}
            />
          </Box>)}
        </div>
      </DataListContain>
    </Box>
  );
};


export default function CompanyDirectory() {
  return (
    <Grid container spacing={2} padding={2} mt={3} sx={{ alignItems: 'stretch' }}>
      {<CompanyListContainer />}
    </Grid>
  );
}
